import React, { IframeHTMLAttributes } from "react";
import styled from "styled-components";
import { position } from "polished";

const IframeContainer = styled.div<{ aspect: number }>`
  position: relative;
  width: 100%;
  padding-bottom: calc(100% / ${(p) => p.aspect});
  iframe {
    ${position("absolute", 0, 0, 0, 0)};
    width: 100%;
    height: 100%;
  }
`;

export const iframe: React.FunctionComponent<IframeHTMLAttributes<unknown>> = ({
  width = 1,
  height = 1,
  ...props
}) => {
  const widthInt = typeof width === "number" ? width : parseInt(width);
  const heightInt = typeof height === "number" ? height : parseInt(height);
  const aspect = widthInt / heightInt;
  return (
    <IframeContainer aspect={aspect}>
      <iframe {...props} />
    </IframeContainer>
  );
};
