import React from "react";
import styled from "styled-components";
import {
  Table,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from "@material-ui/core";

export {
  TableHead as thead,
  TableBody as tbody,
  TableRow as tr,
  TableCell as td,
} from "@material-ui/core";

export * from "./iframe";

export const h1 = styled(Typography).attrs({
  variant: "h1",
})``;

export const h2 = styled(Typography).attrs({
  variant: "h2",
})``;

export const h3 = styled(Typography).attrs({
  variant: "h3",
})`
  margin-inline-start: 2rem;
`;

export const h4 = styled(Typography).attrs({
  variant: "h4",
})`
  margin-inline-start: 4rem;
`;

export const h5 = styled(Typography).attrs({
  variant: "h5",
})`
  margin-inline-start: 6rem;
`;

export const h6 = styled(Typography).attrs({
  variant: "h6",
})`
  margin-inline-start: 8rem;
`;

export const p = styled(Typography).attrs({
  variant: "body2",
})`
  margin: 1rem 0;
`;

export const div = styled(Typography).attrs({
  variant: "body2",
  component: "div",
})`
  margin: 1rem 0;
`;

export const table = (props) => (
  <TableContainer>
    <Table size="small" stickyHeader={true} {...props} />
  </TableContainer>
);

export const th = styled(TableCell).attrs({ component: "th" })``;

export const figure = styled.figure`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const figcaption = styled(Typography).attrs({
  variant: "caption",
  component: "figcaption",
})`
  text-align: center;
`;
