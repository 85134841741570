import React from "react";
import { graphql } from "gatsby";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

import Layout from "../../components/layout";
import RemarkRenderer from "../../components/remark-renderer";
import CodeLogos from "../../components/code-logos";

const StyledArticle = styled.article`
  padding: 0 calc(25vw - 6rem);
`;

const Post = ({ data }) => {
  const {
    contentfulPost: {
      title,
      tags,
      summary,
      body: {
        childMarkdownRemark: { htmlAst },
      },
    },
  } = data;
  return (
    <Layout title={title} keywords={tags} description={summary}>
      <StyledArticle>
        <Typography variant="h1" component="h1">
          {title}
        </Typography>
        <CodeLogos size={5} tags={tags} justify="center" />
        <RemarkRenderer html={htmlAst} />
      </StyledArticle>
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query BlogPostById($id: String!) {
    contentfulPost(id: { eq: $id }) {
      title
      tags
      summary
      body {
        childMarkdownRemark {
          htmlAst
        }
      }
    }
  }
`;
