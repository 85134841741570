import React from "react";
import { withStyles } from "@material-ui/core";
import RehypeReact from "rehype-react";
import * as components from "./post-components";

const GOLDEN_RATIO = 1.618;

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components,
}).Compiler;

const scrollbar = (theme) => ({
  "&::-webkit-scrollbar": {
    borderRadius: theme.shape.borderRadius,
    height: theme.shape.borderRadius * 2,
    backgroundColor: "rgba(255,255,255,0.1)",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: theme.shape.borderRadius,
    border: "1px solid rgba(0,0,0,0.47)",
    backgroundColor: "rgba(255,255,255,0.3)",
  },
  overflowX: "auto",
  overflowY: "hidden",
});

const styles = (theme) => {
  const {
    typography: { h6, body1 },
  } = theme;
  return {
    "@global": {
      a: {
        textDecoration: "none",
        color: theme.palette.primary.main,
      },
      hr: {
        borderColor: theme.palette.divider,
      },
      li: {
        color: theme.palette.text.primary,
      },
      "span.katex-display": { ...h6, ...scrollbar(theme) },
      pre: {
        boxShadow: theme.shadows[2],
        borderRadius: theme.shape.borderRadius,
        ...scrollbar(theme),
      },
      blockquote: {
        fontStyle: "italic",
        color: theme.palette.text.secondary,
      },
    },
  };
};

const RemarkRenderer = ({ html }) => {
  return <>{renderAst(html)}</>;
};

export default withStyles(styles)(RemarkRenderer);
